import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, Title } from "../../components/Core";
import GlobalContext from "../../context/GlobalContext";
import priceBoxBanner from './../../assets/image/png/price-box-banner.png';

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  background: white;
  border-radius: 30px;
  border: 1px solid #000;
  padding: 20px;
  max-width: 500px;
  margin: auto;
  color: black;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background: #000;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: 0 0 0 8px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledTitle = styled(Title)`
  text-transform: uppercase;
  font-weight: 800;
  margin: 0;
  position: relative;
  top: 2px;
`;

const StyledPrice = styled(Title)`
  font-weight: 800;
  font-size: 24px;
  margin: 0;
  position: relative;
  top: 6px;
  small {
    font-size: 14px !important;
  }
`;

const SpeedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`;

const TitleWrapper  = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    text-align: center;
    img {
      margin: auto;
    }
  }
`;

const StyledButton = styled(Button)`
  padding: 0 1.3rem;
  margin-top: 8px;
`;

const PriceBanner = styled.small`
  line-height: 1.2;
  border: 1px solid green;
  border-radius: 6px;
  padding: 4px;
  font-size: 12px;
  margin-top: 3px;
  strong {
    font-weight: 800 !important;
  }
`;

export const getPrice = (product) => {
  if (product?.DiscountValue > 0) {
    let discountedPrice;

    if (product?.DiscountType === "AMOUNT") {
      discountedPrice =
        Number(product?.UnitPrice) - Number(product?.DiscountValue);
    } else {
      discountedPrice =
        Number(product?.UnitPrice) -
        Number(product?.UnitPrice) * (Number(product?.DiscountValue) / 100);
    }

    return discountedPrice;
  } else {
    return Number(product?.UnitPrice);
  }
};

export const getUtmCampaign = (product) => { 
  return product?.DisplayName.replace(' ', '').toUpperCase();
}

const PricingBox = ({ logo, planName, speed, product, trustpilot }) => {
  const gContext = useContext(GlobalContext)
  return (
    <CardContainer>
      <LogoContainer>
        <Logo src={logo} alt="Company Logo" />
      </LogoContainer>
      <DetailsContainer>
      <TitleWrapper>
        <StyledTitle variant="card" mb="12px">
          {planName}
        </StyledTitle>
        <img src={trustpilot} alt="Trustpilot" width={200}/>
      </TitleWrapper>
      <SpeedWrapper>
        <div className='flex'>
          <StyledTitle variant="card" mb="12px">{speed}&nbsp;</StyledTitle><small className='pt-2'>Mbps</small>
        </div>
        <div>
          &nbsp;&nbsp;18 month contract
        </div>
      </SpeedWrapper>
      <TitleWrapper>
        <StyledPrice variant="card" mb="12px">
        {'£' + getPrice(product).toFixed(2)}&nbsp;<small className='pt-2'>a month</small>
        </StyledPrice>
        <PriceBanner>
          <strong>No price rise</strong><br />
          during contract
        </PriceBanner>
        <a href={process.env.GATSBY_YOUFIBRE_URL + '?pid=' + gContext.goGetRegistrationData()?.SearchPremiseResults?.properties?.SPRN + `&utm_source=Referral&utm_medium=Netomnia_listing&utm_campaign=${getUtmCampaign(product)}`}>
          <StyledButton
            variant="outline"
          >
            Buy now
          </StyledButton>
        </a>
      </TitleWrapper>
      </DetailsContainer>
    </CardContainer>
  );
};

export default PricingBox;